import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import RowSet from '../../../primitives/grid/row-set';
import { StyledButtonAsLink } from '../../../primitives/styled-button';
import Background from '../../../primitives/backgrounds';
import Mobile from '../../../primitives/devices/mobile';
import TabletHorizontal from '../../../primitives/devices/tablet-horizontal';
import Col from '../../../primitives/grid/col';
import Row from '../../../primitives/grid/row';
import MediaItem from '../../../primitives/media-item';
import QualityBadges from '../../../primitives/quality-badges';
import { getLink } from '../../../primitives/link';
import { breakpoints } from '../../../primitives/tokens';
import { FooterContext } from '../../../footer';

export type PageEndCtaProps = Queries.PageEndCtaPropsFragment;
const PageEndCta = ({ richTitle, text, tabletMediaItem, mobileMediaItem, link }: PageEndCtaProps): JSX.Element => (
    <FooterContext.Provider value>
        <Background breakpoints={{
            dt: {
                gradient: 'greyAlpha',
            },
        }}
        >
            <Container css={{
                paddingTop: '170px',
                paddingBottom: '50px',
                textAlign: 'center',
                [breakpoints.tb]: {
                    paddingTop: '130px',
                },
                [breakpoints.mb]: {
                    paddingTop: '90px',
                },
            }}
            >
                <RowSet breakpoints={{ dt: { between: 30 }, tb: { between: 22 }, mb: { between: 16 } }}>
                    <Typography
                        fontSize={{
                            dt: 6368,
                            tb: 4453,
                            mb: 3234,
                        }}
                        override
                        fontWeight="light"
                        css={{
                            maxWidth: '930px',
                            margin: 'auto',
                            [breakpoints.mb]: {
                                maxWidth: '70%',
                            },
                        }}
                    >
                        <CustomStructuredTextRenderer data={richTitle} />
                    </Typography>
                    <Typography
                        fontSize={{
                            dt: 1822,
                            tb: 1627,
                        }}
                        css={{
                            maxWidth: '780px',
                            margin: 'auto',
                        }}
                    >
                        {text}
                    </Typography>
                    {link && link.length > 0 && (
                        <StyledButtonAsLink
                            css={{
                                [breakpoints.mb]: {
                                    width: '148px',
                                },
                            }}
                            to={getLink(link[0].link)}
                        >
                            {link[0].title}
                        </StyledButtonAsLink>
                    )}
                </RowSet>
            </Container>
            <div css={{
                maxHeight: 'min(28vw, 390px)',
                overflow: 'hidden',
                position: 'relative',
                [breakpoints.mb]: {
                    height: '40vw',
                    maxHeight: 'unset',
                },
            }}
            >
                <Container>
                    <Row>
                        <Col breakpoints={{ dt: { span: 10, start: 1 }, mb: { span: 4, start: 0 } }}>
                            <div css={{
                                margin: 'auto',
                                maxWidth: '800px',
                                height: '100%',
                                width: '100%',
                                maxHeight: 'min(28vw, 390px)',
                                position: 'relative',
                                [breakpoints.mb]: {
                                    paddingLeft: '16%',
                                    width: '120%',
                                },
                            }}
                            >
                                <TabletHorizontal>
                                    <MediaItem
                                        css={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        objectFit="cover"
                                        objectPosition="left center"
                                        item={tabletMediaItem}
                                    />
                                    <div css={{
                                        position: 'absolute',
                                        right: '-17%',
                                        top: '9.5%',
                                        width: '24%',
                                        height: '100%',
                                        zIndex: 1,
                                        [breakpoints.mb]: {
                                            right: 'unset',
                                            left: '-20%',
                                        },
                                    }}
                                    >
                                        <Mobile>
                                            <MediaItem
                                                css={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                objectFit="cover"
                                                objectPosition="center center"
                                                item={mobileMediaItem}
                                            />
                                        </Mobile>
                                    </div>
                                </TabletHorizontal>
                                <div css={{
                                    position: 'absolute',
                                    bottom: '25%',
                                    height: '72px',
                                    width: '200px',
                                    left: '-14%',
                                    [breakpoints.mb]: {
                                        zIndex: 1,
                                        left: '10%',
                                        bottom: '-24px',
                                    },
                                }}
                                >
                                    <QualityBadges />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </Background>
    </FooterContext.Provider>
);

export default PageEndCta;

export const query = graphql`
    fragment PageEndCtaProps on DatoCmsPageEndCta {
        richTitle {
            value
            links
            blocks
        }
        text
        mobileMediaItem {
            ...MediaItemProps
        }
        tabletMediaItem {
            ...MediaItemProps
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
